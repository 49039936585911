<template>
  <table-view v-loading="loading" class="diagnosis-and-improvement" :show-page="false">
    <div ref="scrollRef" class="diagnosis-and-improvement__scroll">
      <el-input v-if="editable" class="diagnosis-and-improvement__title-input" v-model="tableData.paramName">
        <i class="el-icon-edit el-input__icon" slot="suffix"></i>
      </el-input>
      <h3 v-if="!editable" class="diagnosis-and-improvement__title">{{ this.tableData.paramName }}</h3>
      <!--  body  -->
      <el-tree
        ref="treeRef"
        :data="treeData"
        node-key="id"
        :draggable="editable"
        default-expand-all
        :expand-on-click-node="false"
        :render-content="renderContent">
      </el-tree>
    </div>
    <div class="diagnosis-and-improvement__footer" :style="{'--text-align':editable?'center':'right'}">
      <el-button v-permission="['tree:edit']" class="fl" type="primary" plain v-if="editable"
                 @click="append()">添加一级节点
      </el-button>
      <el-button class="save-btn" v-permission="['tree:edit']" v-if="editable" type="primary"
                 @click="save">保存
      </el-button>
      <el-button :class="{'fr':editable}" v-permission="['tree:edit']" :type="editable?'danger':'primary'"
                 :plain="editable"
                 @click="handleEditable">
        {{ editable ? '关闭' : '开启' }}编辑模式
      </el-button>
    </div>
  </table-view>
</template>
<script>
import { addParams } from '@/api/sysParams'
import { isEqual, throttle } from 'lodash'

export default {
  name: 'Tree',
  data () {
    return {
      loading: false,
      editable: false,
      tableData: {}, // 整条字典，包括id,paramKey等
      initData: {}, // 原始数据，用于比较有没有修改
      updateFlag: false, // 判断是否被修改数据，是的话可以执行保存操作
      treeData: [] // JSON树
    }
  },
  mounted () {
    this.renderTree()
  },
  methods: {
    async renderTree () {
      this.loading = true
      try {
        const res = await this.$http.getSysParamsByKey('tree')
        this.tableData = res.data
        this.treeData = JSON.parse(res.data.paramValue)
        this.setInitData(this.tableData)
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    // 保存最开始的formData数据,用于比较是否修改表单
    setInitData (data) {
      this.initData = this.cloneDeep(data)
    },
    edit (node, data = {}) {
      this.$prompt('请输入节点内容', '编辑节点', {
        inputType: 'textarea',
        inputPattern: /^.+$/,
        inputValue: data.label,
        inputErrorMessage: '节点内容为必填，而且禁止换行。'
      }).then(({ value }) => {
        data.label = value
      })
    },
    append (node, data = {}) {
      const newChild = {
        id: '',
        label: '',
        children: []
      }
      if (!node) {
        // 没有传node=新增一级
        newChild.id = this.treeData.length + 2
      } else {
        newChild.id = `${data.id}-${data.children?.length + 1}`
      }
      this.$prompt('请输入节点内容', '添加节点', {
        inputType: 'textarea',
        inputPattern: /^.+$/,
        inputErrorMessage: '节点内容为必填，而且禁止换行。'
      }).then(({ value }) => {
        newChild.label = value
        if (!node) {
          // 没有传node=新增一级
          this.treeData.push(newChild)
          this.$nextTick(() => {
            this.$refs.scrollRef.scrollTo(0, this.$refs.treeRef.$el.clientHeight)
          })
        } else {
          if (!data.children) {
            this.$set(data, 'children', [])
          }
          data.children.push(newChild)
        }
      })
    },
    remove (node, data) {
      this.$confirm('确定要删除该节点及其所有子节点吗？').then(() => {
        const parent = node.parent
        const children = parent.data.children || parent.data
        const index = children.findIndex(d => d.id === data.id)
        children.splice(index, 1)
      })
    },
    renderContent (h, {
      node,
      data
    }) {
      if (this.editable) {
        return (
          <span class="custom-tree-node">
            <span>{node.label}</span>
            <span class="tree-node-tool">
              <el-button class="tree-node__del" type="text" icon="el-icon-remove"
                         on-click={() => this.remove(node, data)} />
              <el-button className="ml-1 tree-node__edit" icon="el-icon-edit-outline" type="text"
                         on-click={() => this.edit(node, data)} />
              {node.level < 7 ? <el-button class="ml-1 tree-node__add" icon="el-icon-circle-plus" type="text"
                                           on-click={() => this.append(node, data)} /> : ''}
            </span>
          </span>)
      } else {
        return (
          <span class="custom-tree-node">
            <span>{node.label}</span>
          </span>)
      }
    },
    // 比较数据是否有修改
    compareData () {
      this.updateFlag = false
      Object.keys(this.tableData).forEach(key => {
        const result = isEqual(this.tableData[key], this.initData[key])
        if (!result) {
          // tableData 的字段值存在且与initData的不相等时,才判断为 true 已修改
          if (this.tableData[key] || Number(this.tableData[key]) === 0) {
            // 已修改
            this.updateFlag = true
          }
        }
      })
    },
    handleEditable () {
      if (this.editable) {
        this.tableData.paramValue = JSON.stringify(this.treeData)
        this.compareData()
        if (this.updateFlag) {
          this.$confirm('确定要关闭修改模式吗？（修改后请先保存数据再关闭编辑模式）').then(() => {
            this.editable = false
            this.renderTree()
          })
        } else {
          this.editable = false
          this.renderTree()
        }
      } else {
        this.editable = true
      }
    },
    // 保存
    save: throttle(async function () {
      this.tableData.paramValue = JSON.stringify(this.treeData)
      await this.compareData()
      if (!this.updateFlag) {
        this.$message.error('数据未被修改')
      }
      this.$confirm('点击确定继续保存修改后的数据').then(async () => {
        this.loading = true
        try {
          await addParams(this.tableData)
          this.$message.success('保存成功！')
          await this.renderTree()
          this.editable = false
        } catch (e) {
        } finally {
          this.loading = false
        }
      }).catch(async () => {
        await this.renderTree()
      })
    }, 1000)
  }
}
</script>

<style lang="scss">
.diagnosis-and-improvement {
  --border-color: #c3d4e3;
  --border-width: 1px;
  --left: 36px;
  height: calc(100vh - var(--header-height));
  padding-top: 0;
  overflow: hidden;

  .diagnosis-and-improvement__title {
    text-align: center;
    font-size: 38px;
    line-height: 2;
  }

  .diagnosis-and-improvement__title-input {
    margin: 38px 10%;
    width: 80%;

    .el-input__inner {
      height: 60px;
      text-align: center;
      padding: 12px;
      font-size: 38px;
      font-weight: 700;
      color: #404040;
    }

    .el-input__icon {
      margin-right: 1rem;
      font-size: 24px;
    }
  }

  .diagnosis-and-improvement__footer {
    margin-top: 8px;
    padding: 10px;
    justify-content: unset;
    text-align: var(--text-align);
    box-shadow: 0 4px 6px 2px rgba(0, 0, 0, 0.3);

    .save-btn {
      margin: 0 auto;
    }
  }

  .table-view__body {
    width: 100%;
    height: calc(100% - 68px);
    margin: 0;
  }

  .diagnosis-and-improvement__scroll {
    overflow: auto;
    height: 100%;
    scroll-behavior: smooth;
  }

  .el-tree {
    margin-bottom: 28px;
    font-weight: 700;
    width: 100%;
    color: #404040;

    .el-tree-node__content {
      padding-top: 14px;
      height: auto;
      background: transparent;

      &:hover, &:active, &:focus-visible {
        background: transparent;
      }
    }

    .el-tree-node__expand-icon {
      z-index: 19;
      transform: translateX(30px) rotate(0deg);

      &.expanded {
        transform: translateX(30px) rotate(90deg);
      }
    }

    .custom-tree-node {
      position: relative;
      max-width: calc(100% - var(--left) - 40px);
      font-size: 15px;
      padding: 7px 11px 7px 31px;
      border: var(--border-width) solid var(--border-color);
      border-radius: 6px;
      white-space: normal;
      word-break: break-all;

      .tree-node-tool {
        margin-left: 10px;

        .el-button {
          font-size: 20px;
          padding: 0;

          &.tree-node__del {
            color: var(--color-danger);
          }

          &.el-button.tree-node__edit {
            width: 20px;
            height: 20px;
            margin-top: -2px;
            font-size: 16px;
            border-radius: 50%;
            border: 1px solid var(--color-primary);
            transform: translateY(-2px);
          }

          &:hover {
            opacity: .7;
          }
        }
      }

    }

    > .el-tree-node {
      //第二级
      .el-tree-node__children {
        --left: 36px;
        background-image: linear-gradient(to bottom, var(--border-color), var(--border-color));
        background-repeat: no-repeat;
        background-size: var(--border-width) 100%;
        background-position: var(--left) 0;

        // 最高级不需要这个线
        .el-tree-node__content {
          padding-left: 0 !important;
          position: relative;
          background-color: transparent;
          background-image: linear-gradient(to bottom, var(--border-color), var(--border-color));
          background-repeat: no-repeat;
          margin-left: var(--left);
          background-size: 36px var(--border-width);
          background-position: 0 center;

          .custom-tree-node {
            background: #fff;

            &:hover{
              background: #ebeff4;
            }
          }

          &:before {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 50%;
            width: var(--border-width);
            background: var(--border-color);
            content: '';
          }
        }

        > .el-tree-node {
          //第三级
          > .el-tree-node__children {
            --left: 72px;

            > .el-tree-node {
              > .el-tree-node__children {
                --left: 108px;

                > .el-tree-node {
                  > .el-tree-node__children {
                    --left: 144px;

                    > .el-tree-node {
                      > .el-tree-node__children {
                        --left: 180px;

                        > .el-tree-node {
                          > .el-tree-node__children {
                            --left: 216px;

                            > .el-tree-node {
                              > .el-tree-node__children {
                                --left: 252px;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          &:last-child {
            background-image: linear-gradient(to right, transparent var(--left), #fff var(--left));
          }
        }

      }

    }
  }

}
</style>
